import React, { Component } from "react";
import SearchBox from "./ecosystems/SearchBox";
import { bindActionCreators } from "redux";
import { fetchBookings } from "../../redux/actions/bookings";
import { fetchList } from "../../redux/actions/dealerships";
import queryString from "query-string";
import moment from "moment";
import { connect } from "react-redux";
import dealership from "../data/dealership";

class MonthlyBillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: {},
      vatInclude: false,
      grand_total: 183664,
      grand_total_comission: 36733,
      grand_total_discount: 0,
      grand_total_bookings: 33,
      addOn: 0
    };
    this.handleVatChage = this.handleVatChage.bind(this);
    moment.locale("th");
  }
  onSubmit = values => {
    this.props.fetchBookings(values);
  };
  onClear = () => {};
  addCommas = intNum => {
    return (intNum + "").replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  };
  handleVatChage = () => {
    this.setState({
      vatInclude: !this.state.vatInclude,
      addOn: this.state.addOn * this.state.addOnType
    });
  };

  renderList() {
    const { bookings } = this.props;
    if (bookings.fetching) {
      return <h4>...Loading</h4>;
    }else if(bookings.error){
      return(
        <div className="notification is-danger">
        <h3> Error connection failed!</h3>
      </div>
      )
    } else if (
      bookings.sucessfully &&
      bookings.branchesBookings.length < 1
    ) {
      return (
        <div className="notification is-danger">
          <h3> Error data not found!</h3>
        </div>
      );
    } else if(bookings.branchesBookings > 0) {
      bookings.branchesBookings.map(branch => {
        return (
          <table
            className="table is-bordered is-stripped is-hoverable is-fullwidth"
            style={{ width: "95%", fontSize: "14px" }}
            key={branch.id}
          >
            <thead>
              <tr>
                <th
                  colSpan="13"
                  style={{ backgroundColor: "#e0e0e0" }}
                >{`สาขา ${branch.id} ${branch.name}`}</th>
              </tr>
              <tr>
                <th>#</th>
                <th>{`Booking id`}</th>
                <th>{`Customer full name`}</th>
                <th>{`Custel`}</th>
                <th>{`วันรับรถ`}</th>
                <th>{`เวลา`}</th>
                <th>{`วันคืนรถ`}</th>
                <th>{`เวลา`}</th>
                <th>{`ทะเบียนรถ`}</th>
                <th>{`ราคา`}</th>
                <th>{`คอมมิชชั่น`}</th>
                <th>{`Discount`}</th>
                <th>{`Remark`}</th>
              </tr>
            </thead>
            <tbody>
              {branch.bookings &&
                branch.bookings.map((item, key) => {
                  return (
                    <tr key={item.booking_id}>
                      <td>{key + 1}</td>
                      <td>{item.id}</td>
                      <td>{item.customer_name}</td>
                      <td>{item.customer_tel}</td>
                      <td>
                        {moment(
                          item.booking_begin,
                          "YYYY-MM-DDTHH:mm:ssZ"
                        ).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {moment(
                          item.booking_begin,
                          "YYYY-MM-DDTHH:mm:ssZ"
                        ).format("HH:mm")}
                      </td>
                      <td>
                        {moment(
                          item.booking_end,
                          "YYYY-MM-DDTHH:mm:ssZ"
                        ).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {moment(
                          item.booking_end,
                          "YYYY-MM-DDTHH:mm:ssZ"
                        ).format("HH:mm")}
                      </td>
                      <td>{item.car.license_plate}</td>
                      <td>{this.addCommas(item.price / 100)}</td>
                      <td>{this.addCommas(item.comission / 100)}</td>
                      <td>0</td>
                      <td />
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={9}>Total</td>
                <td>
                  {branch.total_price && this.addCommas(branch.total_price)}
                </td>
                <td>
                  {branch.total_comission &&
                    this.addCommas(branch.total_comission)}
                </td>
                <td>0</td>
                <td />
              </tr>
              <tr>
                <td colSpan={12}>Comission</td>
                <td>
                  {branch.total_comission &&
                    branch.total_price &&
                    this.addCommas(branch.total_comission - 0)}
                </td>
              </tr>
            </tfoot>
          </table>
        );
    })
  }
}
  componentDidMount() {
    this.props.fetchList().then(() => {});
  }
  componentWillUpdate(nextProps, nextState) {
    return nextProps.dealerships !== this.props.dealerships;
  }
  render() {
    const { bookings, dealerships, fetchList } = this.props;

    const { grand_total, grand_total_comission, vatInclude } = this.state;
    return (
      <div className="container">
        <SearchBox
          onSubmit={this.onSubmit}
          dealerships={dealerships.dealerships}
          onSearch={fetchList}
        />
        <div className="columns is-mobile">
          <div className="column is-11">
            {this.renderList()}
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchBookings, fetchList }, dispatch);
};
const mapStateToProps = state => ({
  bookings: state.bookingList,
  dealerships: state.dealerships
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyBillingPage);
