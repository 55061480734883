import { defineAction } from "redux-define";

export const FETCH_ACTION = defineAction("BOOKINGS", [
  "FETCHING",
  "FETCHED",
  "ERROR",
  "SUCCESS",
  "INITIAL",
  "FETCHED_TOTAL_DEALERS"
]);

const initialState = {
  fetching: false,
  error: false,
  branchesBookings: [],
  sucessfully: false,
  total_bookings: 0
};
const reducer = (state = initialState, { type, payload, isNext }) => {
  switch (type) {
    case FETCH_ACTION.INITIAL: {
      return initialState;
    }
    case FETCH_ACTION.FETCHING: {
      return {
        ...state,
        fetching: true,
        error: false,
        sucessfully: false
      };
    }
    case FETCH_ACTION.ERROR: {
      return {
        ...state,
        fetching:false,
        error: true,
        message: payload,
        sucessfully: false
      };
    }
    case FETCH_ACTION.FETCHED: {
      const branchesBookings = payload.data;
      let list = [];
      return {
        ...state,
        error: false,
        fetching: false,
        branchesBookings: list,
        sucessfully: true
      };
    }
    case FETCH_ACTION.FETCHED_TOTAL_DEALERS: {
      return {
        ...state,
        total_dealer: payload
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
