import axios from 'axios'
import moment from 'moment'
import {LEGACY_URL, ADMIN_API} from '../../variables/switching'
import { FETCH_ACTION } from '../reducers/bookingList'
import queryString from 'query-string'

export const fetchBookings = (query) => dispatch =>{
    query.month = moment(query.month, 'MM/YY').format('MM/YYYY')
    dispatch({type:FETCH_ACTION.FETCHING, payload:query})
    return axios.get(`${ADMIN_API}/api/v1/dealers/bookings?${queryString.stringify(query)}`).then(res =>{
        dispatch({type:FETCH_ACTION.FETCHED, payload:res.data})
    }).catch(err =>{
        dispatch({type:FETCH_ACTION.ERROR, payload:err.message})
    })

}