import axios from 'axios'
import moment from 'moment'
import {ADMIN_API} from '../../variables/switching'
import { FETCH_ACTION } from '../reducers/dealerships'
import queryString from 'query-string'

export const fetchList = (query={}) => dispatch =>{
    dispatch({type:FETCH_ACTION.FETCHING})
    return axios.get(`${ADMIN_API}/dealers?${queryString.stringify(query)}`).then(response =>{
        dispatch({type:FETCH_ACTION.FETCHED, payload:response.data})
    }).catch(err =>{
        dispatch({type:FETCH_ACTION.ERROR, payload:err.message})
    })

}